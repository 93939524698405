import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
// 引入非功能性样式
import "./assets/css/index.less";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router/index.js"


const app=createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
